import styled from 'styled-components'

const variables = {
  decorWidth: '20px',
}

const TestimonialsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`

const TestimonialsImgWrapperStyled = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 100%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 5.75rem;
  height: 5.75rem;
  margin: 0 auto calc(${({ theme }) => theme.gutter.gutter16} * 1.25);
`

const TestimonialsImgStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  margin: 0 auto;
`

const TestimonialsHeaderStyled = styled.div`
  position: relative;
  margin-bottom: calc(${({ theme }) => theme.gutter.gutter16} * 1.25);
  flex-grow: 1;

  &::after {
    display: block;
    content: '';
    width: ${variables.decorWidth};
    height: 1px;
    background: ${(props) => props.theme.colors.red};
    margin: calc(${(props) => props.theme.gutter.gutter16} * 1.25) auto 0;
  }
`

const TestimonialsNameStyled = styled.div`
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: ${(props) => props.theme.gutter.gutter16};
`

const TestimonialsDetailsStyled = styled.div`
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  color: ${(props) => props.theme.colors.lightkGrey};

  .agency {
    margin-top: calc(${(props) => props.theme.gutter.gutter16} / 8);
    color: ${(props) => props.theme.colors.mediumGrey};
  }
`

const TestimonialsQuoteStyled = styled.blockquote`
  font-style: italic;
  color: ${(props) => props.theme.colors.mediumGrey};
`

export {
  TestimonialsStyled,
  TestimonialsImgWrapperStyled,
  TestimonialsImgStyled,
  TestimonialsHeaderStyled,
  TestimonialsNameStyled,
  TestimonialsDetailsStyled,
  TestimonialsQuoteStyled,
}
