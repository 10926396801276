import styled from 'styled-components'

import { media } from '../../common/MediaQueries'

const FeatureItemStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
  height: 144px;
  width: 100%;
  max-width: 174px;
  margin: 0 auto;

  &:after {
    content: '';
    position: absolute;
    left: 20px;
    bottom: 26px;
    width: 150px;
    height: 108px;
    background-color: ${({ theme }) => theme.colors.lightTiber};
  }

  @media ${media.phone} {
    grid-template-columns: repeat(6, 1fr);
  }
`

const FeatureItemTypoStyled = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.2;
  z-index: 1;
`

const FeatureItemIconStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  z-index: 1;
`

export { FeatureItemStyled, FeatureItemTypoStyled, FeatureItemIconStyled }
