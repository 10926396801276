import React from 'react'

import { graphql } from 'gatsby'
import SEO from '../components/Seo'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Section from '../components/Section'
import Flex from '../components/Flex'

import Hero from '../components/Hero'
import CtaBox from '../components/CtaBox'
import ServiceIcons from '../components/ServiceIcons'
import InfoBox from '../components/InfoBox'
import Clients from '../components/Clients'
import FeatureItem from '../components/FeatureItem'
import Testimonials from '../components/Testimonials'
import TextBlock from '../components/TextBlock'
import Slider from '../components/Slider'

import { Typo2, Typo3, Typo6 } from '../components/Typography'
import ButtonLink from '../components/ButtonLink/index'
import {
  ButtonLinkType,
  ButtonLinkSize,
  ButtonLinkWidth,
} from '../components/ButtonLink/styles'

import { HEADER_TYPES } from '../constants/headerTypes'
import { HEADER_BUTTON_TYPES } from '../constants/headerButtonTypes'

import ReactIcon from '../assets/svg/ReactIcon'
import ReactNativeIcon from '../assets/svg/ReactNativeIcon'
import GatsbyIcon from '../assets/svg/GatsbyIcon'

import { reactDevelopers } from '../mocks/reactDevelopersMock'
import { clients } from '../mocks/clientsMock'
import { features } from '../mocks/featuresMock'
import { testimonials } from '../mocks/testimonialsMock'
import { developers } from '../mocks/developersMock'

const HeroCompaniesBgImg = require('../assets/images/hero-companies-bg.png') as string
const PageproIcon = require('../assets/images/svg/pagepro-dark-logo.svg') as string

const IndexPage = () => (
  <Layout
    headerType={HEADER_TYPES.light}
    headerButtonType={HEADER_BUTTON_TYPES.companies}
  >
    <SEO title="JS Minds - Connecting remote React developers with ambitious companies." />
    <Container bg="white">
      <Hero
        pt={['2rem', '34px']}
        pb={['2rem', '50px']}
        containerBackgroundImage={`url(${HeroCompaniesBgImg})`}
      >
        <CtaBox
          title={
            <Typo2 as="h1" color="darkNavy" fontWeight="bold">
              Hire verified <br />
              <span>React Developers</span>
            </Typo2>
          }
          description={
            <Typo3 color="darkNavy" fontWeight="bold">
              Aligned to your timeline, budget, and company culture.
            </Typo3>
          }
          action={
            <ButtonLink
              className="button"
              type={ButtonLinkType.primary}
              size={ButtonLinkSize.medium}
              href="https://pipedrivewebforms.com/form/45d388f2f4deb5ef7d30266c2cd46b164551864"
              rel="noopener noreferrer"
              target="_blank"
            >
              Hire Now
            </ButtonLink>
          }
          media={
            <ServiceIcons
              color="red"
              items={[
                {
                  id: 1,
                  icon: <ReactIcon />,
                },
                { id: 2, icon: <ReactNativeIcon /> },
                {
                  id: 3,
                  icon: <GatsbyIcon />,
                },
              ]}
            />
          }
          direction={['column', '']}
          pbContent={['2rem', '2rem']}
        />
      </Hero>
    </Container>
    <Container
      bg="lightGrey"
      pt={['2.875rem', '4.5rem']}
      pb={['2.875rem', '4.5rem']}
    >
      <Section
        pbHead={['2rem', '3.7rem']}
        pbBody={['2rem', '2.8rem']}
        heading={
          <Typo3 as="h2" fontWeight="bold" textAlign="left">
            Top React Developers <span>Aligned</span> To Your Needs
          </Typo3>
        }
        action={
          <ButtonLink
            className="button"
            type={ButtonLinkType.primary}
            size={ButtonLinkSize.medium}
            fluid={ButtonLinkWidth.full}
            href="https://pipedrivewebforms.com/form/45d388f2f4deb5ef7d30266c2cd46b164551864"
            rel="noopener noreferrer"
            target="_blank"
          >
            Hire Now
          </ButtonLink>
        }
      >
        <Flex flexDirection={['column', 'row']}>
          {reactDevelopers.map(
            ({ heading, src, alt, description, additionalDescription }) => (
              <Flex flex={['', '1']} pb={['1rem', '']} key={heading}>
                <Container width={['100%', '100%']} pr={['', '3.7rem']}>
                  <InfoBox {...{ heading, src, alt }}>
                    <Typo6>{description}</Typo6>
                    {additionalDescription && (
                      <Typo6>{additionalDescription}</Typo6>
                    )}
                  </InfoBox>
                </Container>
              </Flex>
            )
          )}
        </Flex>
      </Section>
    </Container>
    <Container pt={['2.875rem', '4.5rem']} pb={['2.875rem', '4.5rem']}>
      <Section
        pbHead={['2rem', '2.875rem']}
        heading={
          <Typo3 as="h2" fontWeight="bold" textAlign="left">
            Our Clients' <span>projects</span> were featured in
          </Typo3>
        }
      >
        <Clients items={clients} />
      </Section>
    </Container>
    <Container bg="tiber" pt={['2.875rem', '4rem']} pb={['2.875rem', '4.5rem']}>
      <Section
        pbHead={['2.5rem', '3.7rem']}
        pbBody={['2rem', '2.8rem']}
        heading={
          <Typo3 as="h2" color="white" fontWeight="bold" textAlign="left">
            Appreciated by many for being
          </Typo3>
        }
      >
        <Flex
          flexDirection={['column', 'row']}
          alignItems={['center', 'flex-start']}
        >
          {features.map(({ src, alt, description, additionalDescription }) => (
            <Flex
              flex={['', '1']}
              pb={['1.5rem', '']}
              width={['100%', '']}
              key={alt}
            >
              <Container pr={['', '3.7rem']} width={['100%', '']}>
                <FeatureItem
                  {...{ description, additionalDescription, alt }}
                  icon={src}
                />
              </Container>
            </Flex>
          ))}
        </Flex>
      </Section>
    </Container>
    <Container pt={['2.875rem', '4rem']} pb={['2.875rem', '4.5rem']}>
      <Section
        pbHead={['3rem', '4.5rem']}
        pbBody={['2rem', '2.8rem']}
        heading={
          <Typo3 as="h2" fontWeight="bold" textAlign="left">
            What Clients Said About Working With <span>JSMinds</span>?
          </Typo3>
        }
      >
        <Flex
          flexDirection={['column', 'row']}
          alignItems={['center', 'flex-start']}
        >
          {testimonials.map(({ name, profession, agency, quote, src, alt }) => (
            <Flex
              flex={['', '1']}
              pb={['2rem', '']}
              width={['100%', '']}
              key={alt}
            >
              <Container pr={['', '3.7rem']} width={['100%', '']}>
                <Testimonials {...{ name, src, alt, profession, agency }}>
                  "{quote}"
                </Testimonials>
              </Container>
            </Flex>
          ))}
        </Flex>
      </Section>
    </Container>
    <Container
      bg="lightGrey"
      overflow="hidden"
      pt={['2.875rem', '4.5rem']}
      pb={['2.875rem', '4.5rem']}
    >
      <Section
        pbHead={['2.5rem', '3.7rem']}
        pbBody={['2rem', '2.8rem']}
        heading={
          <Typo3 as="h2" fontWeight="bold" textAlign="left">
            Choose Your <span>React Special Forces</span>
          </Typo3>
        }
        action={
          <ButtonLink
            className="button"
            type={ButtonLinkType.primary}
            size={ButtonLinkSize.medium}
            fluid={ButtonLinkWidth.full}
            href="https://pipedrivewebforms.com/form/45d388f2f4deb5ef7d30266c2cd46b164551864"
            rel="noopener noreferrer"
            target="_blank"
          >
            Hire Now
          </ButtonLink>
        }
        hasCustomInner
      >
        <Slider items={developers} slideBackground="white" />
      </Section>
    </Container>
    <Container pt={['2.875rem', '4.5rem']} pb={['2.875rem', '4.5rem']}>
      <Section>
        <Flex flexDirection={['column', 'row']}>
          <Flex flex={['', '1']} pb={['3rem', '0']} width="100%">
            <Container pr={['', '3.7rem']}>
              <TextBlock src={PageproIcon} alt="Pagepro Logo" />
            </Container>
          </Flex>
          <Flex flex={['', '2']} pb={['1rem', '0']} width="100%">
            <Container width="100%" pr={['', '7rem']}>
              <TextBlock
                head={
                  <Typo3 as="h3" fontWeight="bold" textAlign="left">
                    Who is behind JS Minds?
                  </Typo3>
                }
                body={
                  <>
                    <Typo6 color="darkNavy">
                      JSMind been made by Pagepro - a React Rebels and
                      JavaScript Superheroes that are delivering products and
                      training hundreds of quality developers already for more
                      than 11 years.
                    </Typo6>
                    <Typo6 color="darkNavy">
                      They support and advising many big brands with their own
                      unique hiring, training,  and career process for React
                      developers.
                    </Typo6>
                    <Typo6 color="darkNavy">
                      Their expertise and development insights have been also
                      used by many well-known development companies worldwide.
                    </Typo6>
                    <Typo6 color="darkNavy">
                      At the moment, Pagepro pipeline is the main source of all
                      the JSMinds projects.
                    </Typo6>
                  </>
                }
              />
            </Container>
          </Flex>
        </Flex>
      </Section>
    </Container>
  </Layout>
)

export default IndexPage
