import React from 'react'

import {
  FeatureItemStyled,
  FeatureItemTypoStyled,
  FeatureItemIconStyled,
} from './styles'
import { Typo8 } from '../Typography'

interface IProps {
  icon: string
  alt: string
  description: string
  additionalDescription?: string
}

const FeatureItem: React.FC<IProps> = ({
  description,
  additionalDescription,
  icon,
  alt,
}) => (
  <FeatureItemStyled>
    <FeatureItemIconStyled>
      <img src={icon} {...{ alt }} />
    </FeatureItemIconStyled>
    <FeatureItemTypoStyled>
      <Typo8 fontWeight="bold" textAlign="left">
        {description}
      </Typo8>
      {additionalDescription && (
        <Typo8 fontWeight="bold" textAlign="left">
          {additionalDescription}
        </Typo8>
      )}
    </FeatureItemTypoStyled>
  </FeatureItemStyled>
)

export default FeatureItem
