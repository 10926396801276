import { IInfoboxItem } from '../components/Section'

const ClockIcon = require('../assets/images/svg/clock.svg') as string
const SkillsIcon = require('../assets/images/svg/skills.svg') as string
const ResearchIcon = require('../assets/images/svg/research.svg') as string

export const reactDevelopers: IInfoboxItem['items'] = [
  {
    heading: 'Flexible contracts',
    headingIcon: '',
    description:
      'From full-time positions to part-time roles and direct hiring.',
    additionalDescription:
      'Choose what’s best for your timeline, budget, or company culture.',
    src: ClockIcon,
    alt: 'Clock',
  },
  {
    heading: 'Painless Teamwork',
    headingIcon: '',
    description: 'Soft skills are as important as development skills here.',
    additionalDescription:
      'Being well-organized, highly communicative, and proactive is a must.',
    src: ResearchIcon,
    alt: 'Research',
  },
  {
    heading: '186 Skills Checked',
    headingIcon: '',
    description:
      'Developers`re going through a restless list of 186 skills from questionaire built during 11 years of running JS consultancy.',
    additionalDescription: 'Try them on a risk-free trial.',
    src: SkillsIcon,
    alt: 'Skills',
  },
]
