import { IInfoboxItem } from '../components/Section'

const TimerIcon = require('../assets/images/svg/timer.svg') as string
const SupportIcon = require('../assets/images/svg/support.svg') as string
const BullseyeIcon = require('../assets/images/svg/bullseye.svg') as string

export const features: IInfoboxItem['items'] = [
  {
    heading: '',
    headingIcon: '',
    description: 'Always',
    additionalDescription: 'on time',
    src: TimerIcon,
    alt: 'Timer',
  },
  {
    heading: '',
    headingIcon: '',
    description: 'Insightful',
    additionalDescription: 'and supportive',
    src: SupportIcon,
    alt: 'Support',
  },
  {
    heading: '',
    headingIcon: '',
    description: 'Highly',
    additionalDescription: 'responsive',
    src: BullseyeIcon,
    alt: 'Bullseye',
  },
]
