import { ITestimonialItem } from '../components/Section'

const ChristianBarnes = require('../assets/images/christian-barnes.jpg') as string
const KristofHamilton = require('../assets/images/kristof-hamilton.jpg') as string
const SebRichards = require('../assets/images/seb-richards.jpg') as string

export const testimonials: ITestimonialItem['items'] = [
  {
    name: 'CHRISTIAN BARNES',
    profession: 'Digital Project Manager',
    agency: 'LUMINOUS, UK',
    src: ChristianBarnes,
    alt: 'Christian Barnes',
    quote:
      'Proactive, supportive and deliver good quality code – very happy with the products that have been built to date.',
  },
  {
    name: 'KRISTOF HAMILTON',
    profession: 'Head of digital',
    agency: 'PRIDE IN LONDON, UK',
    src: KristofHamilton,
    alt: 'Kristof Hamilton',
    quote: `They've clearly been fully committed members of the project, with a constant high-qualit code delivery and good proficiency.`,
  },
  {
    name: 'SEB RICHARDS',
    profession: 'Co-Founder',
    agency: 'POLY AGENCY, UK',
    src: SebRichards,
    alt: 'Seb Richards',
    quote:
      'Their development team plugged-in and made a great front-end extension to our IT department. Together we could handle many projects at the same time!',
  },
]
